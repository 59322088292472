import { useCookie, useRouter, useRuntimeConfig } from '#imports'
import { computed, ref } from 'vue'

import { cookieConsentAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { retry } from '@backmarket/utils/function/retry'
import { defineStore } from 'pinia'

import { COOKIES, GDPR_COOKIE_DURATION_IN_MONTHS } from '../constants'
import type { Cookie, CustomizableCookie, UserCookies } from '../types'
import { createCookieConfig } from '../utils/createCookieConfig'

import { useTracking } from './useTracking'

export const useLegalStore = defineStore('legal', () => {
  const {
    public: { tracking: config },
  } = useRuntimeConfig()

  const consent = ref<Record<Cookie, boolean>>({
    advertising: false,
    analytics: false,
    functional: true,
    userExperience: false,
  })
  const isModalOpen = ref(false)

  // Cookie modal must be hidden on some pages (legal pages) but displayed afterwards.
  const shouldHideModal = computed(() => {
    const { currentRoute } = useRouter()

    return currentRoute.value?.meta?.noCookieModal
  })

  function close() {
    isModalOpen.value = false
  }

  function setCookies(choices: UserCookies) {
    Object.entries(COOKIES).forEach(([key, cookieName]) => {
      const cookieValue = choices[key as CustomizableCookie]

      // update the store with the choices
      consent.value[key as Cookie] = cookieValue

      // create/update user's browser Cookies with the choices
      const cookieRef = useCookie(
        cookieName,
        createCookieConfig(GDPR_COOKIE_DURATION_IN_MONTHS),
      )
      cookieRef.value = cookieValue.toString()
    })
  }

  function load({ cookiesFromBrowser }: { cookiesFromBrowser: UserCookies }) {
    const { market } = useMarketplace()

    const storedCookiesList: CustomizableCookie[] = Object.entries(
      cookiesFromBrowser,
    ).map(([key, value]) => {
      // update the store with the value of the stored cookies
      consent.value[<CustomizableCookie>key] = value

      return key as CustomizableCookie
    })

    // Find the cookies keys that are missing from the user's browser Cookies
    const missingCookies = Object.keys(COOKIES).filter(
      (cookieName) =>
        !storedCookiesList.includes(cookieName as CustomizableCookie),
    )

    if (config.countriesWithNoCookieOptIn.includes(market.countryCode)) {
      // Automatically accept cookies missing from the user's browser Cookies
      setCookies(
        missingCookies.reduce(
          (acc, cookie) => ({ ...acc, [<CustomizableCookie>cookie]: true }),
          cookiesFromBrowser,
        ),
      )
    } else {
      isModalOpen.value = missingCookies.length > 0
    }
  }

  async function save(settings: Record<string, unknown>) {
    try {
      const visitorId = useVisitorId()
      const { trackGDPR } = useTracking()

      const choices = Object.keys(COOKIES).reduce<UserCookies>(
        (acc, key) => {
          const value =
            typeof settings[<CustomizableCookie>key] === 'boolean'
              ? settings[<CustomizableCookie>key]
              : consent.value[<CustomizableCookie>key]

          return {
            ...acc,
            [key]: value,
          }
        },
        { functional: true } as Record<Cookie, boolean>,
      )

      await retry(async () =>
        $httpFetch(cookieConsentAPI.postPreferencesUserCookies, {
          body: {
            identifier: visitorId,
            choices,
          },
        }),
      )

      trackGDPR(choices)

      setCookies(choices)
    } catch (cause) {
      throw new Error('Retry failed', { cause })
    }
  }

  return {
    consent,
    isModalOpen,
    shouldHideModal,
    close,
    load,
    save,
    setCookies,
  }
})
