import { useNuxtApp, useRuntimeConfig } from '#imports'

import type { Locale } from '@backmarket/http-api'

import { NUXT_STATE_KEY } from '../constants'

/**
 * @returns The current locale used by the translation system.
 *
 * @example
 * const locale = useI18nLocale()
 */
export function useI18nLocale() {
  // FIXME: We should not cast the value to `Locale`. This is likely wrong
  // FIXME: since the returned value looks like `en-us`, not `en_US`.

  const nuxtApp = useNuxtApp()
  const { i18n } = useRuntimeConfig().public

  const nuxtLocale: Locale = nuxtApp.payload.state[NUXT_STATE_KEY]?.locale

  return nuxtLocale || i18n.fallbackLocale
}
