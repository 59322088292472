import { computed } from 'vue'

import { Breakpoint, BreakpointQueries } from '../dom/getCurrentBreakpoint'

import { useMediaQuery } from './useMediaQuery'

/**
 * Return the current breakpoint based on the window size.
 */
export function useDangerouslyComputedBreakpoint() {
  const breakpointsQuery = {
    [Breakpoint.LG]: useMediaQuery(BreakpointQueries[Breakpoint.LG]),
    [Breakpoint.MD]: useMediaQuery(BreakpointQueries[Breakpoint.MD]),
    [Breakpoint.SM]: useMediaQuery(BreakpointQueries[Breakpoint.SM]),
    [Breakpoint.XS]: useMediaQuery(BreakpointQueries[Breakpoint.XS]),
  }

  const breakpoints = [
    Breakpoint.LG,
    Breakpoint.MD,
    Breakpoint.SM,
    Breakpoint.XS,
  ]

  return computed<Breakpoint>(
    () =>
      breakpoints.find((breakpoint) => breakpointsQuery[breakpoint].value) ??
      Breakpoint.LG,
  )
}
