export const CUSTOMER_CARE = {
  DIAGNOSIS: {
    SELF: 'diagnosis',
  },
  RESOLUTION: {
    SELF: 'resolution',
    RESOLUTION_CONTACT_CONFIRMATION: 'resolution_contact_confirmation',
    RESOLUTION_RETURN_CONFIRMATION: 'resolution_return_confirmation',
    RESOLUTION_STRAIGHT_REFUND_CONFIRMATION:
      'resolution_straight_refund_confirmation',
    EXPRESS_REPLACEMENT: {
      SELF: 'express_replacement',
      DETAILS: 'express_replacement_details',
      SHIPPING: 'express_replacement_shipping',
      DATA_REMOVAL: 'express_replacement_data_removal',
      PICTURES: 'express_replacement_pictures',
      CONTACT: 'express_replacement_contact',
      PAYMENT: 'express_replacement_payment',
    },
  },
}
