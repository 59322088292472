import { defineNuxtRouteMiddleware, useResponseHeader } from '#imports'

import type { Owner } from '@backmarket/nuxt-module-logger/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

function hasRequiredKey(auth: object): auth is { required?: boolean } {
  return (auth as { required?: boolean }).required !== undefined
}

export default defineNuxtRouteMiddleware((to) => {
  const logger = useLogger()

  // Skip middleware execution on client-side
  // Cache headers only need to be set during server-side rendering
  if (import.meta.client) return

  const cacheRule = to.meta.cache
  const isAuthRequired =
    to.meta.auth &&
    hasRequiredKey(to.meta.auth) &&
    to.meta.auth.required === true

  // Scenario 1: Auth required route has cache defined - this is a mistake
  if (isAuthRequired && cacheRule) {
    logger.error(
      `Route "${to.path}" requires authentication but has cache rule defined. Cache rules should NOT be set on authenticated routes. The cache header will not be applied.`,
      { owners: to.meta.owners as Owner[] },
    )

    // Exit without setting cache header
    // Note: Fallback configuration may exist in Cloudflare, contact infra team for more information
    return
  }

  // Scenario 2: Public route with no cache rule - gentle reminder
  if (!isAuthRequired && !cacheRule) {
    // Will be uncomment with "Log info for public routes without cache meta"
    // https://backmarket.atlassian.net/browse/B2CS-1125
    // logger.info(
    //   `Route "${to.path}" is public but has no cache rule defined. Consider adding a cache rule to improve performance.`,
    //   { owners: to.meta.owners as Owner[] },
    // )

    // Exit without setting cache header
    // Note: Fallback configuration may exist in Cloudflare, contact infra team for more information
    return
  }

  // Normal case: Public route with cache rule
  if (cacheRule) {
    // Set the Cache-Control header
    const header = useResponseHeader('Cache-Control')
    header.value = cacheRule
  }
})
