import {
  defineNuxtRouteMiddleware,
  useRequestEvent,
  useRuntimeConfig,
} from '#imports'
import { getRequestURL } from 'h3'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { getContentSecurityPolicies } from '../config/getContentSecurityPolicies'
import { appendCSPResponseHeaders } from '../utils/appendCSPResponseHeaders'

/**
 * Middleware that adds CSP headers to the http response.
 * The content of those headers is defined in the `../config/getContentSecurityPolicies.ts` file.
 */
export default defineNuxtRouteMiddleware(
  function paymentContentSecurityPoliciesHeaders() {
    if (!import.meta.server) {
      return
    }

    if (useRuntimeConfig().KILL_CONTENT_SECURITY_POLICIES) {
      // Kill switch to disable this middleware
      // See run book: https://backmarket.atlassian.net/wiki/x/GYC8FgE
      return
    }

    const event = useRequestEvent()
    if (!event) {
      // should never happen. Event is always defined server-side
      return
    }

    appendCSPResponseHeaders(
      event,
      getContentSecurityPolicies({
        runtimeConfig: useRuntimeConfig(event),
        requestURL: getRequestURL(event),
        country: useMarketplace().market.countryCode,
      }),
    )
  },
)
