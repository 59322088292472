import type { LoggerAttributes } from '@backmarket/front-logger'
import {
  type HttpContext,
  HttpEvent,
} from '@backmarket/http-api/src/utils/types'
import type { Owner } from '@backmarket/nuxt-module-logger/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { ERROR_TYPE, type ErrorType, LOGGED_HTTP_STATUS_CODE } from './config'

interface LogErrorParams {
  attributes?: LoggerAttributes
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
  errorType?: Exclude<ErrorType, 'feature_error' | 'fetch_error'>
  owners?: Owner[]
}

interface LogFeatureErrorParams {
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
  featureName?: string
  owners?: Owner[]
}

interface LogHttpErrorParams {
  error: Error
  errorStatusCode: number
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
  owners?: Owner[]
}

interface LogHttpOnEventParams<T, B> {
  event: HttpEvent
  context: HttpContext<T, B>
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
  owners?: Owner[]
}

interface useCareLoggerReturnValue {
  logError: ({ attributes, errorTitle }: LogErrorParams) => void
  logFeatureError: ({
    errorDetail,
    errorTitle,
    featureName,
  }: LogFeatureErrorParams) => void
  logHttpError: ({ error, errorDetail, errorTitle }: LogHttpErrorParams) => void
  logHttpOnEvent: <T, B>({
    context,
    event,
    errorDetail,
    errorTitle,
  }: LogHttpOnEventParams<T, B>) => void
}

export const useCareLogger = (): useCareLoggerReturnValue => {
  const logger = useLogger()

  const logError = ({
    attributes,
    errorDetail,
    errorTitle,
    errorType,
    owners = ['bot-squad-care-platform-front'],
  }: LogErrorParams) => {
    logger.error(`${errorTitle}`, {
      ...attributes,
      error_context: {
        error_type: errorType ?? ERROR_TYPE.uncategorized,
        error_detail: errorDetail ?? '',
      },
      owners,
    })
  }

  const logFeatureError = ({
    errorDetail,
    errorTitle,
    featureName,
    owners = ['bot-squad-care-platform-front'],
  }: LogFeatureErrorParams) => {
    logger.error(`${errorTitle}`, {
      error_context: {
        feature_name: featureName ?? '',
        error_type: ERROR_TYPE.feature,
        error_detail: errorDetail ?? '',
      },
      owners,
    })
  }

  const logHttpError = ({
    error,
    errorStatusCode,
    errorDetail = '',
    errorTitle,
    owners = ['bot-squad-care-platform-front'],
  }: LogHttpErrorParams) => {
    if (LOGGED_HTTP_STATUS_CODE.includes(errorStatusCode)) {
      logger.error(`${errorTitle}`, {
        error,
        error_context: {
          error_details: errorDetail,
          error_type: ERROR_TYPE.fetch,
          error_status_code: errorStatusCode,
        },
        owners,
      })
    }
  }

  const logHttpOnEvent = <T, B>({
    context,
    errorTitle,
    event,
    owners = ['bot-squad-care-platform-front'],
  }: LogHttpOnEventParams<T, B>) => {
    if (event === HttpEvent.Fail && context.error) {
      logHttpError({
        error: context.error,
        errorStatusCode: context.error.status,
        errorTitle,
        owners,
      })
    }
  }

  return {
    logError,
    logFeatureError,
    logHttpError,
    logHttpOnEvent,
  }
}
