import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import { isBrowser } from '@backmarket/utils/env/isBrowser'

import { getDeviceId as getAmplitudeDeviceId } from '../amplitude'
import { COOKIES } from '../constants'

import { SDK_ENDPOINT } from './constants'

export type BrazeInstance = typeof import('@braze/web-sdk')
let instance = <BrazeInstance>{}
let brazeApiKey = ''
let isOptInCookieNeededForBraze = true

async function setUpBrazePlugin(consent: boolean) {
  const initOptions = {
    baseUrl: SDK_ENDPOINT,
    enableLogging: false,
  }

  // even though it is not intuitive, the SDK needs to be enabled/disabled before it is initialized
  if (!consent) {
    instance.disableSDK()
  } else {
    instance.enableSDK()
  }

  instance.initialize(brazeApiKey, initOptions)

  return instance
}

function isInstanceAvailable() {
  return Object.keys(instance).length > 0
}

function isInstanceDisabled() {
  return !isInstanceAvailable() || Boolean(instance?.isDisabled())
}

function hasStoredAmplitudeDeviceId() {
  const localStorage = useLocalStorage()
  const amplitudeDeviceId = localStorage.getItem('amplitudeDeviceId')

  return !!amplitudeDeviceId
}

function setAmplitudeDeviceId() {
  const amplitudeDeviceId = getAmplitudeDeviceId()

  if (isInstanceDisabled() || !amplitudeDeviceId) {
    return
  }

  instance
    .getUser()
    ?.setCustomUserAttribute('amplitude_device_id', amplitudeDeviceId)

  const localStorage = useLocalStorage()
  localStorage.setItem('amplitudeDeviceId', amplitudeDeviceId)
}

const setGoogleAdCustomAttributes = ({
  hasUserConsent,
}: {
  hasUserConsent: boolean
}) => {
  const logger = useLogger()

  if (isInstanceDisabled()) {
    return
  }

  const userHandle = instance.getUser()

  const userDataResult = userHandle?.setCustomUserAttribute(
    '$google_ad_user_data',
    hasUserConsent,
  )
  const personalizationResult = userHandle?.setCustomUserAttribute(
    '$google_ad_personalization',
    hasUserConsent,
  )

  const isBrazeConsentAttributeSent = userDataResult && personalizationResult

  if (isBrazeConsentAttributeSent) {
    logger.info('[LIF][BrazeConsent] User consent sent to Braze', {
      owners: ['bot-squad-lifecycle-front'],
    })
  } else {
    logger.error(
      '[LIF][BrazeConsent] Error while sending user consent to Braze',
      {
        owners: ['bot-squad-lifecycle-front'],
      },
    )
  }
}

export function setUserContext({ crmId }: { crmId?: string }) {
  if (isInstanceDisabled()) {
    return
  }

  if (crmId) {
    instance.changeUser(crmId)
    instance.openSession()
  }

  if (!hasStoredAmplitudeDeviceId()) {
    setAmplitudeDeviceId()
  }
}

export const sendEvent = (
  eventName: string,
  payload: Record<string, unknown>,
) => {
  if (isInstanceDisabled()) {
    return
  }

  instance.logCustomEvent(eventName, payload)
}

type InitBrazeParameters = {
  apiKey: string
  isOptInCookieNeeded: boolean
}

// Initialization called when Didomi is disabled
export const initBraze = async ({
  apiKey,
  isOptInCookieNeeded,
}: InitBrazeParameters) => {
  instance = await import('@braze/web-sdk')
  brazeApiKey = apiKey
  isOptInCookieNeededForBraze = isOptInCookieNeeded

  const areNeededCookiesAccepted =
    getBrowserCookie(COOKIES.userExperience) === 'true' &&
    getBrowserCookie(COOKIES.advertising) === 'true'

  const optIn = isOptInCookieNeededForBraze ? areNeededCookiesAccepted : true

  return setUpBrazePlugin(optIn)
}

// Initialization called when Didomi is enabled
export const init = async ({
  apiKey,
  enabled,
}: {
  apiKey: string
  enabled: boolean
}) => {
  instance = await import('@braze/web-sdk')
  brazeApiKey = apiKey

  return setUpBrazePlugin(enabled)
}

// called on GDPR update event
export const setOptIn = async (consent: boolean) => {
  if (!isInstanceAvailable()) {
    return
  }

  if (consent) {
    // Braze plugin needs to be initialized again, only enabling it is not enough
    await setUpBrazePlugin(consent)
    setGoogleAdCustomAttributes({ hasUserConsent: consent })
  } else {
    setGoogleAdCustomAttributes({ hasUserConsent: consent })
    instance.requestImmediateDataFlush()
    instance.disableSDK()
  }
}

export function getInstance(): BrazeInstance | null {
  if (!isBrowser() || isInstanceDisabled()) {
    return null
  }

  return instance
}
