import { defineNuxtPlugin, useRoute, useRuntimeConfig } from '#imports'

import type { Market } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useSessionId } from '@backmarket/nuxt-module-identification/useSessionId'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import { datadogRum } from '@datadog/browser-rum'

import {
  init as initAmplitude,
  setUserProperties as setAmplitudeUserProperties,
} from '../amplitude'
import { USER_GROUP_NAME, USER_GROUP_VALUES } from '../amplitude/constants'
import { initBraze } from '../braze'
import { useDataConsent } from '../composables/useDataConsent'
import { useFeatureFlags } from '../composables/useFeatureFlags'
import { COOKIES } from '../constants'
import { setContext, setUserContext } from '../context'
import * as events from '../eventTrackers'
import { initGoogleTagManager } from '../google-tag-manager'
import { initNoodle } from '../noodle'
import { getInitialContext } from '../utils/getInitialContext'
import { initWebVitals } from '../webVitals'

function initializeTracking({
  market,
  features,
}: {
  market: Market
  features: Record<string, unknown>
}) {
  const {
    public: { tracking: config },
  } = useRuntimeConfig()
  const route = useRoute()
  const visitorId = useVisitorId()
  const sessionId = useSessionId()

  const isOptInCookieNeeded = !config.countriesWithNoCookieOptIn.includes(
    market.countryCode,
  )

  // Routes specified in webVitals.allowedRouteNames will be tracking individually,
  // but the rest will be grouped under an "other" name, allowing us to track the whole app.
  const routeName =
    typeof route.name === 'string' &&
    config.webVitals.allowedRouteNames.includes(route.name)
      ? route.name
      : 'other'

  initWebVitals({
    routeName,
    country: market.countryCode,
  })

  const isE2ETestingEnvironment =
    window.navigator?.userAgent === 'MrFatUserAgent'

  /* Provider initialization */

  /* Noodle */
  const shouldInitNoodle = config.noodle.isEnabled && !isE2ETestingEnvironment

  if (shouldInitNoodle) {
    const noodleUserContext = {
      country: market.countryCode,
      locale: market.defaultLocale,
      sessionId,
      visitorId,
    }

    const noodleConfig = {
      environment: config.environment,
      service: config.service,
      url: config.noodle.url,
      version: config.version,
    }

    initNoodle(noodleUserContext, noodleConfig)
  }

  /* Datadog browser RUM */
  if (config.datadogRum.isEnabled) {
    datadogRum.init({
      applicationId: config.datadogRum.applicationId,
      clientToken: config.datadogRum.clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: config.service,
      env: config.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: config.datadogRum.sessionSampleRate,
      sessionReplaySampleRate: config.datadogRum.sessionReplaySampleRate,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }

  /* Amplitude */
  const isAmplitudeCountryCodeEnabled =
    config.amplitude.enabledCountryCodes.includes(market.countryCode)

  const hasUserEnabledAnalytics = isOptInCookieNeeded
    ? getBrowserCookie(COOKIES.analytics) === 'true'
    : true

  const shouldInitAmplitude =
    !isE2ETestingEnvironment &&
    config.amplitude.isEnabled &&
    isAmplitudeCountryCodeEnabled

  if (shouldInitAmplitude) {
    // If analytics are enabled, we init amplitude right here
    // if not, trackGDPR + setOptIn will trigger the init
    if (hasUserEnabledAnalytics) initAmplitude()
    // in any case, we need to identify user as not logged by default at first
    setAmplitudeUserProperties({
      [USER_GROUP_NAME]: USER_GROUP_VALUES.NOT_LOGGED,
    })
  }

  /* Braze */
  const shouldInitBraze = !isE2ETestingEnvironment && config.braze.isEnabled

  if (shouldInitBraze) {
    void initBraze({
      apiKey: config.braze.apiKey,
      isOptInCookieNeeded,
    })
  }

  /* Google Tag Manager */
  initGoogleTagManager({
    isOptInCookieNeeded,
  })

  /* Context setting */
  const initialContext = getInitialContext(
    market,
    features,
    useExperiments(),
    useFeatureFlags(),
    config,
    visitorId,
    sessionId,
    { isDidomiEnabled: false },
  )
  setContext(initialContext)
}

export function createTrackingPlugin() {
  const marketplaceState = useMarketplace()

  const { isDidomiEnabled } = useDataConsent()
  if (isDidomiEnabled) return {}

  if (process.client) {
    initializeTracking(marketplaceState)
  }

  return {
    provide: {
      tracking: {
        ...events,
        setContext,
        setUserContext,
      },
    },
  }
}

export default defineNuxtPlugin(createTrackingPlugin)
