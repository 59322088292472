/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useRoute } from '#imports'
import { type ComponentPublicInstance } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'

// TEMPORARY WORKAROUND: until missmatch warnings/errors are fixed
function logHydrationMismatch(
  msg: string,
  instance: ComponentPublicInstance | null,
  owners: string[],
) {
  const componentName = instance?.$options?.__name || 'UnknownComponent'
  console.groupCollapsed(`🚨 [Hydration Mismatch] in <${componentName}>`)
  console.warn('Message:', msg)
  console.warn('Component Name:', componentName)
  console.warn('Route Owners:', owners)
  console.warn('DOM Element:', instance?.$el)
  console.groupEnd()
}

export default defineNuxtPlugin((nuxtApp) => {
  const logger = useLogger()
  const route = useRoute()
  const config = useRuntimeConfig()

  const owners = route.meta.owners || []

  if (config.public.DEV_HYDRATION_WARNING_DETAILS_ENABLED) {
    // eslint-disable-next-line no-param-reassign
    nuxtApp.vueApp.config.warnHandler = (msg, instance, trace) => {
      if (msg.includes('Hydration children mismatch on')) {
        logHydrationMismatch(msg, instance, owners)
      }

      console.warn(msg, instance, trace)
    }
  }

  nuxtApp.hook('vue:error', (error, instance) => {
    // eslint-disable-next-line no-underscore-dangle
    const componentName = instance?.$options?.__name || 'unknown'

    if (error instanceof Error) {
      logger.error(error.message, {
        error,
        componentName,
        owners,
      })
    }
  })

  nuxtApp.hook('app:error', (error) => {
    logger.error(error.message, {
      error,
      owners,
    })
  })
})
