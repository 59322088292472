export const MODAL_NAMES = {
  SWAP_PRODUCT: 'SWAP_PRODUCT',
  SWAP_ATC: 'SWAP_ATC',
  SWAP_CART: 'SWAP_CART',
  SWAP_CHECKOUT_SUMMARY: 'SWAP_CHECKOUT_SUMMARY',
  SWAP_MIX_AND_MATCH: 'SWAP_MIX_AND_MATCH',
  SWAP_LISTING: 'SWAP_LISTING',
  SWAP_LISTING_MOBILE: 'SWAP_LISTING_MOBILE',
  SWAP_VIEW_OFFER: 'SWAP_VIEW_OFFER',
  SWAP_VIEW_OFFER_MOBILE: 'SWAP_VIEW_OFFER_MOBILE',
  SWAP_SUMMARY: 'SWAP_SUMMARY',
  SWAP_PP_VIEW_OFFER: 'SWAP_PP_VIEW_OFFER',
  MY_SALES_SHIP_ITEM: 'MY_SALES_SHIP_ITEM',
  MY_SALES_KYC_DETAILS: 'MY_SALES_KYC_DETAILS',
  MY_SALES_COUNTER_OFFER: 'MY_SALES_COUNTER_OFFER',
  MY_SALES_CONFIRM_COUNTER_OFFER: 'MY_SALES_CONFIRM_COUNTER_OFFER',
}

// There might be API's returning Link object for a given route
// To avoid stuff breaking keep the same route names of Pastrami
export const ROUTE_NAMES = {
  DEVICE_ASSESSMENT: 'buyback_device_form',
  OFFER: 'buyback_offer',
  NO_OFFER: 'buyback_no_offer',
  BANK: 'buyback_bank',
  ADDRESS: 'buyback_address',
  CONFIRMATION: 'buyback_confirmation',
  SHIPPING: 'buyback_shipping',
  CONVERSATION: 'buyback_conversation',
}

export const ERROR_TYPES = {
  INVALID_ID_ERROR_TYPE: '/errors/buyback/order/invalid-listing-id',
  NOT_FOUND_ERROR_TYPE:
    '/errors/buyback/order/creation/buyback-listing-not-found',
}

export const STEP_NAMES = {
  BANK: 'bank',
  ADDRESS: 'address',
  SHIPPING: 'shipping',
}

export const BUYBACK_PARTNERS = {
  PLAYSTATION: 'playstation',
}
