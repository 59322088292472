export const FUNNEL_ERROR_MESSAGE = {
  GET_CATEGORIES:
    '[BUYBACK][API][FUNNEL] Error getting questions API (categories)',
  GET_QUESTIONS: '[BUYBACK][API][FUNNEL] Error getting questions API',
  EMPTY_QUESTIONS: '[BUYBACK][API][FUNNEL] Empty response from questions API',
  POST_QUESTIONS: '[BUYBACK][API][FUNNEL] Error posting questions API',
  GET_OFFER: '[BUYBACK][API][FUNNEL] Error getting offer',
  POST_ORDER: '[BUYBACK][API][FUNNEL] Error posting buyback order',
  ADD_SWAP: '[BUYBACK][API][FUNNEL] Error adding buyback to cart',
  POST_ADDRESS: '[BUYBACK][API][FUNNEL] Error posting address',
  POST_BANK: '[BUYBACK][API][FUNNEL] Error posting bank details',
  BUYBACK_FUNNEL: '[BUYBACK][JS][FUNNEL] Error JS in buyback funnel',
  GET_SALE_DETAILS: '[BUYBACK][API][FUNNEL] Error getting sale details',
}
