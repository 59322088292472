<template>
  <div>
    <BlockTitle :subtitle :title :title-id="id" />

    <div v-if="hasSingleCategory">
      <CategoryCard :category="onlyCategory" :tracking />
    </div>

    <RevCardGrid v-else :aria-labelledby="id" :cards="categories">
      <template #default="{ card, index }">
        <CategoryCard
          :category="card"
          :tracking="{
            ...tracking,
            position: index + 1,
          }"
        />
      </template>
    </RevCardGrid>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { CategoriesProps } from '@backmarket/http-api/src/api-specs-content/models/categories'
import { RevCardGrid } from '@ds/components/CardGrid'

import type { ContentBlockProps } from '../../models/content-block'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'

import CategoryCard from './CategoryCard.vue'

const props = defineProps<CategoriesProps & ContentBlockProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasSingleCategory = computed(() => props.categories?.length === 1)

const onlyCategory = computed(() => props.categories?.[0])
</script>
