import { useRuntimeConfig } from '#imports'

import { addTrailingSlash } from '@backmarket/utils/url/addTrailingSlash'
import { removeLeadingSlash } from '@backmarket/utils/url/removeLeadingSlash'

const toPublicPath = (path: string) => {
  const config = useRuntimeConfig()

  return config.app?.cdnURL
    ? new URL(removeLeadingSlash(path), addTrailingSlash(config.app.cdnURL))
        .href
    : path
}

export const getDidomiConfig = (shouldDisplayNotice: boolean) => ({
  user: { bots: { consentRequired: false } },
  cookies: {
    storageSources: {
      cookies: true,
      localStorage: false,
    },
  },
  notice: {
    logoUrl: toPublicPath('/img/legal/cookies.png'),
    learnMorePosition: 'after',
    textAlignment: 'center',
    enable: shouldDisplayNotice,
  },
})

export const VENDORS_ID = {
  amplitude: 'amplitude-nzaqxfRQ',
  braze: 'braze-x4LHJX93',
  gtm: 'googletag-Et9AC4np',
}
