import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { CMS } from '~/scopes/cms/routes-names'

/* eslint-disable consistent-return */
export default defineNuxtRouteMiddleware((to, from) => {
  if (!to.meta?.isWebview && from.meta?.isWebview) {
    return navigateTo({
      name: CMS.WEBVIEW.ARTICLE,
      params: to.params,
    })
  }
})
