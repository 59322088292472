import { transformHref } from '@backmarket/front-office/scopes/cms/helpers/link/transformHref'
import type {
  Article,
  ArticleCardsList,
} from '@backmarket/http-api/src/api-specs-content/models/article-cards-list'

// TODO COM-1345 Remove the mapping
export function mapToArticles(props: ArticleCardsList = {}) {
  if ('articles' in props && props.articles) return props.articles
  if ('guides' in props && props.guides) return props.guides

  return []
}

export function mapArticlesToArticleCards(
  currentUrl: URL,
  articles: Article[],
) {
  return articles.map((article) => {
    const { src, alt, ...imageProps } = article.image
    const link = transformHref({ href: article.link.href, currentUrl })

    return {
      category: article.translatedCategory,
      id: article.id,
      imageAlt: alt,
      imageSrc: src,
      link,
      text: article.text,
      title: article.title,
      imageProps: 'width' in imageProps ? imageProps : undefined,
    }
  })
}

export function mapArticleCardsListToArticleCards(
  currentUrl: URL,
  props: ArticleCardsList = {},
) {
  return mapArticlesToArticleCards(currentUrl, mapToArticles(props))
}
