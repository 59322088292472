import { useAsyncData, useRuntimeConfig } from '#imports'
import { computed, reactive, watchEffect } from 'vue'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { Intercom } from '@intercom/messenger-js-sdk'
import type { InitType, UserArgs } from '@intercom/messenger-js-sdk/types'
import { defineStore, storeToRefs } from 'pinia'

import { useCareLogger } from '../../composables/logger/useCareLogger'

import type {
  IntercomMethodArg,
  IntercomMethodType,
  UseIntercomStoreState,
} from './useIntercomStore.types'

/**
 * Pinia store exposing the current intercom instance.
 *
 */
export const useIntercomStore = defineStore('intercom', () => {
  const { logFeatureError, logError } = useCareLogger()
  const { INTERCOM_APP_ID } = useRuntimeConfig().public
  const userStore = useUserStore()
  const { isUserAuthenticated, user } = storeToRefs(userStore)

  const key = getAsyncDataKey('intercom')

  const $httpFetch = useHttpFetch()

  const { data, error, execute } = useAsyncData(
    key,
    async () => {
      const r = await $httpFetch(
        '/service-request/v1/authenticate-intercom-customer',
        {
          method: 'POST',
        },
      )

      return r
    },
    {
      immediate: false,
    },
  )

  const state = reactive<UseIntercomStoreState>({
    canBeBooted: false,
    isAvailable: false,
    settings: {
      app_id: INTERCOM_APP_ID,
      hide_default_launcher: true,
      region: 'eu',
    },
  })

  const intercomCanBeInitiated = computed(
    () => process.client && !state.isAvailable && state.canBeBooted,
  )

  watchEffect(() => {
    if (error.value) {
      logFeatureError({
        errorTitle: '[INTERCOM] error when initiating',
        errorDetail: {
          type: 'fetch',
          context:
            "Failed to fetch the intercom authenticate customer and we can't initiate intercom",
          endpoint: '/service-request/v1/authenticate-intercom-customer',
        },
        featureName: 'intercom_error_when_initiating',
      })
    }

    if (data.value && user.value.email) {
      state.settings = {
        ...state.settings,
        email: user.value.email,
        user_id: state.settings.user_id ?? data.value?.id ?? '',
        user_hash: state.settings.user_hash ?? data.value?.hash ?? '',
      }

      state.canBeBooted = true
    }

    if (intercomCanBeInitiated.value) {
      Intercom(state.settings)

      /* NOTE: Currently, we can't wait for the Intercom SDK to fully initialize 
      *  before setting the `available` ref, as the SDK only provides a void function 
      *  that internally uses a Promise. This is acceptable for the test milestone, 
      but for the MVP, we need a solution to reliably detect when the SDK is ready. * */

      state.isAvailable = true
    }
  })

  /**
   * Initialise Intercom based on customer authentication.
   *
   */

  function initIntercom(
    args: Partial<InitType> = {
      hide_default_launcher: state.settings.hide_default_launcher,
      region: state.settings.region,
    },
  ) {
    state.settings = {
      app_id: state.settings.app_id,
      ...args,
    }
    state.isAvailable = false

    if (!state.isAvailable && isUserAuthenticated.value) {
      void execute()

      return
    }

    state.canBeBooted = true
  }

  function callIntercomMethod(
    method: IntercomMethodType,
    ...args: IntercomMethodArg[]
  ) {
    // window is undefined on server-side and this breaks our widget
    if (import.meta.client && window.Intercom) {
      window.Intercom(method, ...args)

      return
    }

    logError({
      errorTitle:
        '[Intercom] - Trying to call a method when intercom is not available',
      errorType: 'intercom',
      errorDetail: {
        intercomMethod: method,
        environement: import.meta.client ? 'browser' : 'server',
      },
    })
  }

  /**
   * Boot intercom instance.
   *
   */

  function boot(
    args: Partial<InitType> = {
      app_id: state.settings.app_id,
      hide_default_launcher: state.settings.hide_default_launcher,
      region: state.settings.region,
    },
  ) {
    state.settings = {
      app_id: args.app_id ?? state.settings.app_id,
      email: args.email ?? state.settings.email,
      user_id: args.user_id ?? state.settings.user_id,
      user_hash: args.user_hash ?? state.settings.user_hash,
      ...args,
    }

    callIntercomMethod('boot', state.settings)
  }

  /**
   * hide messenger app.
   *
   */

  function hide() {
    callIntercomMethod('hide')
  }

  /**
   * Add a callback when the messenger app close.
   *
   */

  function onHide(callback: (...args: unknown[]) => unknown) {
    callIntercomMethod('onHide', callback)
  }

  /**
   * Add a callback when the messenger app open.
   *
   */

  function onShow(callback: (...args: unknown[]) => unknown) {
    callIntercomMethod('onShow', callback)
  }

  /**
   * Show messenger app.
   *
   */

  function show() {
    callIntercomMethod('show')
  }

  /**
   * Show the corresponding conversation.
   *
   */

  function showConversation(conversationId: string) {
    callIntercomMethod('showConversation', conversationId)
  }

  /**
   * Show all the messages inside the messenger app.
   *
   */

  function showMessages() {
    callIntercomMethod('showMessages')
  }

  /**
   * Shutdown intercom.
   *
   */

  function shutdown() {
    callIntercomMethod('shutdown')

    /*
     * Temp - Disabled those two properties because if we delete the Intercom property inside
     * the window, when we re-initiate intercom it will not be correctly loaded and intercom is no
     * more available.
     * Need to investigate if the problem is comming from the SDK or intercom lifecycle.
     * As this feature is only available in preprod we jave no risk to impact other pages in prod.
     *
     *
     * delete window.Intercom
     * delete window.intercomSettings
     */

    state.isAvailable = false
    state.canBeBooted = false
  }

  /**
   * Update intercom instance method.
   *
   */

  function update(arg: UserArgs) {
    callIntercomMethod('update', arg)
  }

  return {
    intercom: state,
    boot,
    hide,
    initIntercom,
    onHide,
    onShow,
    show,
    showConversation,
    showMessages,
    shutdown,
    update,
  }
})
