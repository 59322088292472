import type { Market } from '@backmarket/http-api'
import { type useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'

import { COOKIES } from '../constants'
import type { ContextData, TrackingConfig } from '../types'

export function getInitialContext(
  market: Market,
  features: Record<string, unknown>,
  experiments: ReturnType<typeof useExperiments>,
  featureFlags: Record<string, unknown>,
  config: TrackingConfig,
  visitorId: string,
  sessionId: string,
  { isDidomiEnabled = false }: { isDidomiEnabled: boolean },
) {
  const context: ContextData = {
    // Experiments
    abtest: experiments,

    // Feature flags
    flags: featureFlags,

    // Market config
    currencyCode: market.defaultCurrency,
    locale: market.defaultLocale,
    country: market.countryCode,
    isCountryRollout: market.status === 'OPEN',

    // Module config
    service: config.service,
    release: config.version,

    // Identification
    visitorId,
    sessionId,
  }

  // GDPR config
  if (process.client && !isDidomiEnabled) {
    context.gdpr = {
      functional: true,
      advertising: getBrowserCookie(COOKIES.advertising) === 'true',
      analytics: getBrowserCookie(COOKIES.analytics) === 'true',
      userExperience: getBrowserCookie(COOKIES.userExperience) === 'true',
    }
  }

  // Features
  if (
    features.trustmark !== undefined &&
    typeof features.trustmark === 'string'
  )
    context.trustmark = features.trustmark

  if (features.showCarrier !== undefined)
    context.hasCarrierFilters = Boolean(features.showCarrier)

  if (features.vatIncluded !== undefined)
    context.hasVATIncluded = Boolean(features.vatIncluded)

  if (
    features.buyback !== undefined &&
    features.buyback !== null &&
    typeof features.buyback === 'object' &&
    'enabled' in features.buyback
  )
    context.hasBuyBack = Boolean(features.buyback.enabled)

  return context
}
