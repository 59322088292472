import humps, { type OptionOrProcessor } from 'humps'

/**
 * Mark a value as needing to be passed through `camelizeKeys` before usage.
 * This is useful for API responses that are not yet camelized but should be,
 * and for which we don't want to create a new non-camelized type.
 *
 * @example
 * type Data = ToCamelize<{ helloWorld: string }>
 * const response = await fetch<Data>('/api/data')
 * console.log(response) // { hello_world: 'hello' }
 *
 * console.log(response.helloWorld) // ko: Type error
 * console.log(camelizeKeys(response).helloWorld) // ok
 */
export type ToCamelize<Type> = {
  'USE `camelizeKeys` BEFORE USING THIS VALUE': Type
}

/**
 * Recursively transform object keys from snake_case to camelCase.
 *
 * This helper is here to provide better type safety around the underlying
 * methods from the `humps` package. We want to throw type errors when
 * this is called with class instances (like FormData, URLSearchParams).
 *
 * @example
 * camelizeKeys({ hello_world: 'hello' }) // { helloWorld: 'hello' }
 */
export function camelizeKeys<Type>(
  object: ToCamelize<Type>,
  optionsOrProcessor?: OptionOrProcessor,
): Type

export function camelizeKeys<
  Type extends
    | Record<string, unknown>
    | Array<Record<string, unknown>>
    | ReadonlyArray<Record<string, unknown>>,
>(object: Type, optionsOrProcessor?: OptionOrProcessor): humps.Camelized<Type>

export function camelizeKeys<Type>(
  object: Type,
  optionsOrProcessor?: OptionOrProcessor,
) {
  return humps.camelizeKeys<Type>(object, optionsOrProcessor)
}
