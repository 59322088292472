import {
  type MaybeRefOrGetter,
  getCurrentScope,
  onScopeDispose,
  ref,
  toRef,
  watchEffect,
} from 'vue'

import { isBrowser } from '../env/isBrowser'

/**
 * Reactive media query. Please consider using `useDangerouslyComputedBreakpoint` instead to get common breakpoints.
 */
export function useMediaQuery(query: MaybeRefOrGetter<string>) {
  if (!isBrowser()) {
    return ref(false)
  }

  let mediaQuery: MediaQueryList | undefined
  const matches = ref(false)

  const update = (event: MediaQueryListEvent) => {
    matches.value = event.matches
  }

  const cleanup = () => {
    if (mediaQuery) {
      if ('removeEventListener' in mediaQuery) {
        mediaQuery.removeEventListener('change', update)
      } else {
        // @ts-expect-error deprecated API
        mediaQuery.removeListener(update)
      }
    }
  }

  watchEffect(() => {
    cleanup()

    mediaQuery = window.matchMedia(toRef(query).value)
    matches.value = mediaQuery.matches

    if ('addEventListener' in mediaQuery) {
      mediaQuery.addEventListener('change', update)
    } else {
      // @ts-expect-error deprecated API
      mediaQuery.addListener(update)
    }
  })

  if (getCurrentScope()) {
    onScopeDispose(cleanup)
  }

  return matches
}
