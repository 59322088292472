<template>
  <RevEditorialCard
    :key="id"
    :alt="imageAlt"
    class="text-left"
    :class="{ 'max-h-[280px] overflow-y-hidden': variant !== 'full' }"
    :content="variant === 'full' ? text : undefined"
    :cta="variant === 'full' ? i18n(translations.readMore) : undefined"
    data-selector="buying-guide-card"
    data-test="article-card"
    :image="imageSrc"
    :image-props
    :rel="link?.rel"
    :target="link?.target"
    :title
    :title-kicker="category"
    title-tag="h3"
    :to="link?.href"
    :variant
    @click="sendTrackingData"
  />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'

import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevEditorialCard, type Variants } from '@ds/components/EditorialCard'

import translations from './ArticleCard.translations'
import type { ArticleCard } from './ArticleCard.types'

const props = defineProps<
  ArticleCard & { tracking?: Tracking; variant?: Variants }
>()

const i18n = useI18n()
const route = useRoute()

const { trackClick } = useTracking()

function sendTrackingData() {
  const zone = props.tracking?.zone || route.name?.toString() || ''
  const trackingData = {
    ...(props.tracking || {}),
    title: props.title,
    zone,
    articleCategory: props.category,
  }

  trackClick(trackingData)
}
</script>
