import { createHttpEndpoint } from '../../utils'

import type {
  GetMerchantData,
  GetMerchantFeatures,
  GetMerchantRegisterMetadata,
} from './merchant.d'

/**
 * Gets merchant data.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-merchants-api/definition#tag/Merchant/operation/get-bm-merchant}
 */
export const getMerchantData = createHttpEndpoint<GetMerchantData.Response>({
  method: 'GET',
  operationId: 'fetchMerchantData',
  path: '/bm/merchant',
  transformResponseToCamelCase: true,
})

/**
 * Register a new seller, and associate it to the currently authenticated user
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-merchants-api/definition#tag/Merchant/operation/post-bm-merchants-register-existing-user}
 */
export const postSellerRegisterExistingUser = createHttpEndpoint({
  method: 'POST',
  operationId: 'merchantRegisterExistingUser',
  path: '/bm/merchants/register-existing-user',
})

/**
 * Retrieves metadata for the register page
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-merchants-api/definition#tag/Merchant/operation/get-bm-merchants-register-metadatae}
 */
export const getMerchantRegisterMetadata =
  createHttpEndpoint<GetMerchantRegisterMetadata.Response>({
    method: 'GET',
    operationId: 'getSellerRegisterMetadata',
    path: '/bm/merchants/register/metadata',
  })

/**
 * Api used by pastrami to know which features are allowed by marketplace
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-experience-api/definition#/default/get_seller_features}
 */
export const getMerchantFeatures =
  createHttpEndpoint<GetMerchantFeatures.Response>({
    method: 'GET',
    operationId: 'getSellerFeaturesV2',
    path: '/api/seller-experience/seller/features',
  })
