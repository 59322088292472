import type {
  BlockType,
  _Block as CMSBlock,
} from '@backmarket/http-api/src/api-specs-content/models/block'
import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import type {
  Inline,
  Block as RichTextBlock,
  Text,
} from '@contentful/rich-text-types'

export type ArticleContentTitle = {
  title: string
  headingType: string
}

const BLOCKS_TO_IGNORE = new Set<Partial<BlockType>>([
  'Quiz',
  'EmailCollector',
  'Button',
  'Countdown',
  'DiscountBanner',
])

export function findTitlesInRichtext(
  contentItems: (RichTextBlock | Inline | Text)[],
  nodeType?: string,
): ArticleContentTitle[] {
  const titles: ArticleContentTitle[] = []
  let concatenation = ''
  for (let i = 0; i < contentItems.length; i += 1) {
    const item = contentItems[i]
    if (item.nodeType === 'heading-2' || item.nodeType === 'heading-3') {
      titles.push(...findTitlesInRichtext(item.content, item.nodeType))
    }

    if (item.nodeType === 'text') {
      concatenation += item.value

      if (i === contentItems.length - 1) {
        titles.push({ title: concatenation, headingType: nodeType ?? '' })
      }
    }
  }

  return titles
}

export function findTitlesInBlocks(blocks: CMSBlock[]) {
  const headings: ArticleContentTitle[] = []
  for (let i = 0; i < blocks.length; i += 1) {
    const { type, props } = blocks[i]

    // These block types don't use h2/h3 tags for the title field so we can ignore them
    if (BLOCKS_TO_IGNORE.has(type)) continue

    if ('title' in props && props.title?.length) {
      headings.push({ title: props.title, headingType: 'heading-2' })
    } else if ('contentTitle' in props && props.contentTitle.length) {
      headings.push({ title: props.contentTitle, headingType: 'heading-2' })
    } else if (type === 'RichText') {
      const { richText } = props as RichTextProps
      headings.push(...findTitlesInRichtext(richText.content))
    }
  }

  return headings
}
