import { useRuntimeConfig } from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { VENDORS_ID } from '../didomi/config'

export function useDataConsent() {
  const { market } = useMarketplace()
  const { didomi } = useRuntimeConfig().public.tracking

  const isDidomiEnabled =
    didomi.isEnabled && didomi.enabledCountryCodes.includes(market.countryCode)

  function showNotice() {
    if (!process.client) return

    window.Didomi.preferences.show('vendors')
  }

  function getUserConsentForVendor(vendor: string): boolean | null {
    if (!isDidomiEnabled || !process.client) return null

    return window.Didomi.getCurrentUserStatus().vendors[vendor].enabled
  }

  function getUserConsentForAmplitude() {
    return getUserConsentForVendor(VENDORS_ID.amplitude)
  }

  return {
    showNotice,
    getUserConsentForAmplitude,
    isDidomiEnabled,
  }
}
