import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import {
  ABOUT_US,
  ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
} from './routes.constants'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLE_CMS_ABOUT_US } = useRuntimeConfig().public

  const enableCMSAboutUs = FF_ENABLE_CMS_ABOUT_US?.includes(market.countryCode)

  const aboutUsRoute: RouteRecordRaw = {
    path: 'about-us',
    name: ABOUT_US,
    component: () => import('./AboutUs.vue'),
    meta: {
      cache: 'public, max-age=300',
      owners: ['bot-squad-acquisition-front'],
    },
  }

  return [
    ...(!enableCMSAboutUs ? [aboutUsRoute] : []),
    {
      name: ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
      path: '/:locale/quality',
      component: () =>
        import(
          '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
        ),
      meta: {
        disableClientSideNavigation: () => true,
        owners: ['bot-squad-acquisition-front'],
      },
    },
  ]
}
