import { Country } from '../standards'

/**
 * Market country codes
 *
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Market.yaml OpenAPI specification}
 */
export enum MarketCountryCode {
  AT = Country.AT,
  AU = Country.AU,
  BE = Country.BE,
  DE = Country.DE,
  ES = Country.ES,
  FI = Country.FI,
  FR = Country.FR,
  GB = Country.GB,
  GR = Country.GR,
  IE = Country.IE,
  IT = Country.IT,
  JP = Country.JP,
  NL = Country.NL,
  PT = Country.PT,
  SE = Country.SE,
  SK = Country.SK,
  US = Country.US,
}

export type CountryCodeValue = `${MarketCountryCode}`

/**
 * Old market country codes
 *
 * Some api endpoint still deliver codes in the old format (eg. fr-fr, en-gb...).
 * The following mapping is meant to help converting them from old to new format,
 * with type safety.
 */
export const OLD_MARKET_COUNTRY_CODES: Record<CountryCodeValue, string> = {
  // AP
  AU: 'en-au',
  JP: 'ja-jp',
  // EU
  AT: 'de-at',
  BE: 'fr-be',
  DE: 'de-de',
  ES: 'es-es',
  FI: 'fi-fi',
  FR: 'fr-fr',
  GB: 'en-gb',
  GR: 'el-gr',
  IE: 'en-ie',
  IT: 'it-it',
  NL: 'nl-nl',
  PT: 'pt-pt',
  SE: 'sv-se',
  SK: 'sk-sk',
  // NA
  US: 'en-us',
}

export type OldMarketCountryCode =
  (typeof OLD_MARKET_COUNTRY_CODES)[CountryCodeValue]
