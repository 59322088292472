import { defineNuxtPlugin, useCookie } from '#imports'

import { useDataConsent } from '../composables/useDataConsent'
import { useLegalStore } from '../composables/useLegalStore'
import { COOKIES } from '../constants'
import type { CustomizableCookie, UserCookies } from '../types'

export default defineNuxtPlugin(() => {
  const { isDidomiEnabled } = useDataConsent()
  if (isDidomiEnabled) return

  const legalStore = useLegalStore()

  const cookiesFromBrowser = Object.entries(COOKIES)
    .filter(([, cookieKey]) => {
      const storageCookie = useCookie<boolean>(cookieKey)

      return typeof storageCookie.value === 'boolean'
    })
    .reduce(
      (acc, [cookieType, cookieKey]) =>
        ({
          ...acc,
          [cookieType as CustomizableCookie]:
            useCookie<boolean>(cookieKey).value,
        }) as Record<CustomizableCookie, boolean>,
      {} as UserCookies,
    )

  legalStore.load({ cookiesFromBrowser })
})
