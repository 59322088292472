import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES as AUTH_ROUTES } from '@/scopes/auth/route-names'

import { CUSTOMER_CARE } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_INTERCOM_ROUTE_RESOLUTION_FLOW_BY_COUNTRY } =
    useRuntimeConfig().public

  const enableIntercomOnResolutionFlow =
    FF_ENABLED_INTERCOM_ROUTE_RESOLUTION_FLOW_BY_COUNTRY?.includes(
      market.countryCode,
    )

  return [
    {
      path: 'dashboard/diagnosis/new/:orderlineId',
      name: CUSTOMER_CARE.DIAGNOSIS.SELF,
      component: () => import('./diagnosis/Diagnosis.vue'),
      meta: {
        auth: {
          required: true,
          // TODO: [FRONT-1199] remove this after global config change
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
        owners: ['bot-squad-sales-customer-care-front'],
        layout: 'customer-care-minimal',
      },
    },
    {
      path: 'dashboard/resolution/new/:orderlineId/:customerIssues',
      name: CUSTOMER_CARE.RESOLUTION.SELF,
      component: () => import('./resolution/ResolutionFlow.vue'),
      meta: {
        auth: {
          required: true,
          // TODO: [FRONT-1199] remove this after global config change
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
        intercom: enableIntercomOnResolutionFlow,
        owners: ['bot-squad-sales-customer-care-front'],
        layout: 'customer-care-minimal',
      },
      children: [
        {
          name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.SELF,
          path: 'express-replacement',
          children: [
            {
              name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.DETAILS,
              path: 'details',
              component: () =>
                import(
                  './resolution/pages/express-replacement/details/ExpressReplacementDetails.vue'
                ),
            },
          ],
        },
      ],
    },
    {
      path: 'dashboard/resolution/confirmation/:resolutionId?',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/contact/ContactConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        owners: ['bot-squad-sales-customer-care-front'],
      },
    },
    {
      path: 'dashboard/resolution/return/confirmation/:resolutionId',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/return/ReturnConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        owners: ['bot-squad-sales-customer-care-front'],
        layout: 'customer-care-minimal',
      },
    },
    {
      path: 'dashboard/resolution/straight-refund/confirmation',
      name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_STRAIGHT_REFUND_CONFIRMATION,
      component: () =>
        import(
          './resolution/confirmationPages/straightRefund/StraightRefundConfirmationPage.vue'
        ),
      meta: {
        auth: {
          required: true,
        },
        owners: ['bot-squad-sales-customer-care-front'],
      },
    },
  ]
}
